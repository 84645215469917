<template>
  <div
    v-if="data"
    class="flex flex-col"
  >
    <div class="flex flex-row items-center justify-between pb-6 pr-4 xl:pb-10">
      <p class="text-2xl font-bold xl:text-[40px]">Блог</p>
      <NuxtLink to="/blog">
        <p class="cursor-pointer font-manrope text-sm font-bold md:text-sm xl:text-xl">
          Все статьи
        </p>
      </NuxtLink>
    </div>
    <div class="items-center gap-8 xl:flex">
      <button
        class="hidden h-12 min-h-[56px] w-12 min-w-[56px] rounded-full bg-dnd_yellow text-white xl:block"
        @click="prevSlide"
      >
        <p class="font-manrope text-2xl text-black">&lt;</p>
      </button>
      <div class="w-full xl:max-w-[calc(100%-170px)]">
        <CCarousel
          ref="myCarousel"
          :items-to-show="1.4"
          :breakpoints="breakpoints"
          @update:model-value="updateCurrentSlide"
        >
          <CSlide
            v-for="(item, index) in blogStore.blogsList || []"
            :key="index"
          >
            <div class="flex px-2 3xl:px-4">
              <CardBlogSlider :blog-data="item" />
            </div>
          </CSlide>
        </CCarousel>
      </div>
      <button
        class="hidden h-12 min-h-[56px] w-12 min-w-[56px] rounded-full bg-dnd_yellow text-white xl:block"
        @click="nextSlide"
      >
        <p class="font-manrope text-2xl text-black">&gt;</p>
      </button>
    </div>
  </div>
  <div v-else>
    <UiIsLoading />
  </div>
</template>

<script setup>
const blogStore = useBlogStore();

const { data } = await useAsyncData(
  'blog',
  async () => {
    try {
      await blogStore.getBlogCategories();
      await blogStore.getAllBlogs({ page: 1 });

      if (!blogStore.blogsList.length) throw new Error('No data');
      if (!blogStore.blogCategories.length) throw new Error('No data');

      return true;
    } catch (error) {
      return false;
    }
  },
  {
    server: false,
    lazy: true,
  },
);

const isClient = ref(false);
const currentSlide = ref(0);
const myCarousel = ref(null);

const breakpoints = {
  320: {
    itemsToShow: 1.1,
    itemsToScroll: 1,
  },
  768: {
    itemsToShow: 2.2,
    itemsToScroll: 1,
  },
  1024: {
    itemsToShow: 2.4,
    itemsToScroll: 1,
  },
  1640: {
    itemsToShow: 2.99,
    itemsToScroll: 1,
  },
};

onMounted(() => {
  isClient.value = import.meta.client;
});

// TODO: its neeed refactoring
const nextSlide = () => {
  if (currentSlide.value === 0) {
    myCarousel.value.slideTo(2);
  } else {
    myCarousel.value.next();
  }
};

const prevSlide = () => {
  if (myCarousel.value.data.maxSlide.value === currentSlide.value) {
    myCarousel.value.slideTo(myCarousel.value.data.maxSlide.value - 2);
  } else {
    myCarousel.value.prev();
  }
};

const updateCurrentSlide = (currentSlideIndex) => {
  currentSlide.value = currentSlideIndex;
};
</script>
